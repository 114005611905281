@use '@fixed-size' as *;
@use '@typography' as *;
@use '@button' as *;
@use '@queries' as *;

.btn {
  @include singleFixedSize(36px);

  @include min-428-break {
    @include singleFixedSize(44px);
  }
}

.liked {
  svg {
    color: #eb3c3c;
  }
}

.icon {
  color: #6941c6;
  @include singleFixedSize(18px);

  @include min-428-break {
    @include singleFixedSize(20px);
  }
}
