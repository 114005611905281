@use '@fixed-size' as *;
@use '@queries' as *;

.container {
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: var(--cornerradius10);

  @include singleFixedSize(24px);

  &-secondary {
    box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.03);
    outline: 2px solid var(--cst-buttons-quaternary-outline-default);
    outline-offset: -2px;
    background: #fff;
    border-radius: var(--cornerradius10);

    @include singleFixedSize(38px);

    @include min-428-break {
      @include singleFixedSize(44px);
    }

    .btn {
      @include singleFixedSize(18px);

      opacity: 1;
      color: var(--global-rainmaker300);

      &-active {
        opacity: 1;
        color: #eb3c3c;
      }

      @include min-428-break {
        @include singleFixedSize(22px);
      }
    }
  }
}

.btn {
  @include singleFixedSize(18px);

  @include min-428-break {
    @include singleFixedSize(24px);
  }

  &-active {
    opacity: 1;
    color: #eb3c3c;
  }
}
